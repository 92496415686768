import React from "react";
import meme1 from "../assets/MEME 1.png";
import meme2 from "../assets/MEME 2.png";
import meme3 from "../assets/MEME 3.png";
import meme4 from "../assets/MEME 4.png";
import meme5 from "../assets/MEME 5.png";
import meme6 from "../assets/MEME 9.png";
import "./Memes.css";

function Memes() {
  return (
    <div className="main-container">
      <h1 className="header header-memes">Memes</h1>
      <div className="memes-container">
        <div className="memes-row">
          <div>
            <img src={meme1} alt="Meme 1" />
          </div>
          <div>
            <img src={meme5} alt="Meme 5" />
          </div>
        </div>
        <div className="memes-row">
          <div>
            <img src={meme3} alt="Meme 3" />
          </div>
          <div>
            <img src={meme6} alt="Meme 6" />
          </div>
        </div>
        <div className="memes-row">
          <div>
            <img src={meme2} alt="Meme 2" />
          </div>
          <div>
            <img src={meme4} alt="Meme 4" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Memes;
