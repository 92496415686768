function About() {
  return (
    <div className="p-3">
      <h1 className="header about-header">About</h1>

      <div className="d-flex p-5 justify-content-center">
        <div className="d-flex flex-column gap-4 about-container">
          <div>ASALAMALAYKAMALADA.</div>
          <div>
            CONER IS THE $CHEMP OF THE UFCU. THIS COIN ISN’T JUST HERE TO TAKE
            PART; IT’S HERE TO TAKE OVER.
          </div>
          <div>
            FORGET ABOUT BORING FINANCIAL ADVICE – CONER MCGREGER IS ALL ABOUT
            MOON KICKS, LAUGH, AND A COMMUNITY AS WILD AS A DUBLIN PUB BRAWL.
            BUY $CHEMP IF U WUNT TO LAUGH AND MEK MONIES. CONER MCGREGER WILL
            MAKE YOU RICH.
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
