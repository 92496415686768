import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container-main">
      <div className="p-3">
        <div className="footer-links-container">
          <h3>coner mcgreger</h3>
          <div className="d-flex gap-3">
            <a
              className="hero-link footer-link-1"
              href="http://t.me/coner_mcgreger"
              target="_blank"
              rel="noopener noreferrer"
            >
              telegram
            </a>
            <button className="hero-link link-2">chart</button>
            <a
              className="hero-link link-3"
              href="https://twitter.com/coner_da_chemp"
              target="_blank"
              rel="noopener noreferrer"
            >
              twitter
            </a>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center justify-content-center gap-4 footer-container">
        <div>
          $CHEMP has no association with Conor Mcgregor. This token is simply
          a meme coin with no intrinsic value or expectation of financial
          return.
        </div>
        <div>© 2024 by coner mcgreger $CHEMP. All rights reserved.</div>
      </div>
    </div>
  );
}

export default Footer;
