import React from "react";
import Nav from "./Nav/Nav";
import Hero from "./Hero/Hero";
import Footer from "./Footer/Footer";
import About from "./About/About";
import Memes from "./Memes/Memes";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Nav />
      <div id="hero">
        <Hero />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="memes">
        <Memes />
      </div>
      <Footer />
    </div>
  );
}

export default App;
