import React, { useEffect, useRef } from "react";
import "./Hero.css";
import img from "../assets/no bg.png";

function Hero() {
  const imgRef = useRef(null);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const moveX = (clientX / window.innerWidth) * 30 - 15; // Adjust the multiplier for more or less movement
      const moveY = (clientY / window.innerHeight) * 30 - 15; // Adjust the multiplier for more or less movement
      if (imgRef.current) {
        imgRef.current.style.transform = `translate(${moveX}px, ${moveY}px) scaleX(-1)`;
      }
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      <img src={img} className="img" ref={imgRef} alt="Parallax Effect"></img>
      <div className="d-flex flex-column justify-content-end align-items-center gap-3 hero-container">
        <div className="hero-links-container">
          <a
            className="hero-link link-1"
            href="http://t.me/coner_mcgreger"
            target="_blank"
            rel="noopener noreferrer"
          >
            telegram
          </a>
          <a href="https://birdeye.so/token/5UCgMp8QvePDTVqvoD2snUXBjruHzsdXsVnrUDPr8ZwG?chain=solana"
            target="_blank"
            rel="noopener noreferrer"
              className="hero-link hero-link-2">chart</a>
          <a
            className="hero-link link-3"
            href="https://twitter.com/coner_da_chemp"
            target="_blank"
            rel="noopener noreferrer"
          >
            twitter
          </a>
        </div>

        <div className="ca-container mb-3">
          CA: 5UCgMp8QvePDTVqvoD2snUXBjruHzsdXsVnrUDPr8ZwG
        </div>
      </div>
    </>
  );
}

export default Hero;
