import "./Nav.css";
import logo from "../assets/no bg.png";

function Nav() {
  return (
    <div className="w-100 nav-container">
      <ul className="d-flex justify-content-between w-100 list">
        <li className="d-flex align-items-center justify-content-center gap-1">
          <img src={logo} className="logo" alt="Logo" />
          <div className="main-header">coner mcgreger</div>
        </li>
        <li>
          <a className="nav-link" href="#about">
            ABOUT
          </a>
        </li>
        <li>
          <a className="nav-link" href="#how-to-buy">
            HOW TO BUY
          </a>
        </li>
        <li>
          <a className="nav-link" href="#memes">
            MEMES
          </a>
        </li>
        <li>
          <button className="main-button">BUY $CHEMP</button>
        </li>
      </ul>
    </div>
  );
}

export default Nav;
